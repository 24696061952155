<template>
  <div class="page">
    <Navbar title="订单处理" :callback="back" type="SMP" />
    <div class="order">
      <van-row class="item">
        <van-col span="6" class="title">订单编号：</van-col>
        <van-col span="18" class="text">{{ orderCode }}</van-col>
      </van-row>
      <van-row class="item">
        <van-col span="6" class="title">职位名称：</van-col>
        <van-col span="12" class="text text-short">{{
          order.taskName
        }}</van-col>
        <van-col span="6" class="btn"
          ><van-tag :color="COLOR_M" size="mini" @click="taskDetail"
            >查看任务</van-tag
          ></van-col
        >
      </van-row>
      <van-row class="item">
        <van-col span="6" class="title">办理难度：</van-col>
        <van-col span="18" class="text">
          <span v-if="order.difficulty === 1" class="color-m"
            >办理简单（符合条件）</span
          >
          <span
            v-if="order.difficulty > 1 && order.difficulty < 100"
            class="color-s1"
            >办理困难（不符合条件）</span
          ></van-col
        >
      </van-row>
      <van-row class="item">
        <van-col span="6" class="title">订单状态：</van-col>
        <van-col span="6" class="text"
          ><van-tag v-if="order.processState === 'RUN'" :color="COLOR_M"
            >运行中</van-tag
          ></van-col
        >
        <van-col span="6" class="title">当前节点：</van-col>
        <van-col span="6" class="text">{{ order.processNodeName }}</van-col>
      </van-row>
      <van-row class="item">
        <van-col span="6" class="title">申请时间：</van-col>
        <van-col span="18" class="text">{{ order.time }}</van-col>
      </van-row>
    </div>
    <van-tabs
      :active="activeName"
      :color="COLOR"
      :border="true"
      @change="tabChange"
      class="detail"
    >
      <van-tab title="个人简历" name="detail">
        <div class="base">
          <van-row class="item">
            <van-col span="12" class="title">姓名：</van-col>
            <van-col span="12" class="desc">{{ person.name }}</van-col>
          </van-row>
          <van-row class="item">
            <van-col span="12" class="title">性别：</van-col>
            <van-col span="12" class="desc">{{ person.genderName }}</van-col>
          </van-row>
          <van-row class="item">
            <van-col span="12" class="title">年龄：</van-col>
            <van-col span="12" class="desc">{{ person.age }}</van-col>
          </van-row>
          <van-row class="item">
            <van-col span="12" class="title">民族：</van-col>
            <van-col span="12" class="desc">{{ person.nationName }}</van-col>
          </van-row>
          <van-row class="item">
            <van-col span="12" class="title">手机号码：</van-col>
            <van-col span="12" class="desc">***********</van-col>
          </van-row>
          <van-row class="item">
            <van-col span="12" class="title">身份证号：</van-col>
            <van-col span="12" class="desc">******************</van-col>
          </van-row>
        </div>
        <DTLBaseView ref="dtlBase" />
        <DTLEduView ref="dtlEdu" />
        <DTLWorkView ref="dtlWork" />
        <DTLCertView ref="dtlCert" />
        <DTLSkillView ref="dtlSkill" />
        <DTLProjectView ref="dtlProject" />
      </van-tab>
      <van-tab title="验证明细" name="match">
        <MatchViewPlug ref="matchView" />
      </van-tab>
    </van-tabs>
    <div class="operate">
      <van-row>
        <van-col span="24">
          <van-button
            class="btn"
            icon="phone-circle-o"
            :color="COLOR_M"
            size="small"
            @click="callPhone"
            v-if="
              order.processNodeKey === 'APPLY' ||
              order.processNodeKey === 'SUPPLIER'
            "
            >回访客户</van-button
          >
          <van-button
            class="btn"
            icon="sign"
            :color="COLOR_S1"
            size="small"
            @click="applyAudit"
            v-if="order.processNodeKey === 'APPLY'"
            >订单初审</van-button
          >
          <van-button
            class="btn"
            icon="sign"
            :color="COLOR_S1"
            size="small"
            @click="supplierAudit"
            v-if="order.processNodeKey === 'SUPPLIER'"
            >订单复审</van-button
          >
        </van-col>
      </van-row>
    </div>
    <Share ref="share" />
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import {
  Tabs, Tab, Divider, Field, RadioGroup, Radio, CellGroup, Cell, Tag
} from 'vant'
import Share from '../../common/Share.vue'
import Loading from '../../common/Loading.vue'
import Navbar from '../../common/Navbar.vue'
import DTLBaseView from '../../people/person/DTLBaseView.vue'
import DTLEduView from '../../people/person/DTLEduView.vue'
import DTLWorkView from '../../people/person/DTLWorkView.vue'
import DTLSkillView from '../../people/person/DTLSkillView.vue'
import DTLCertView from '../../people/person/DTLCertView.vue'
import DTLProjectView from '../../people/person/DTLProjectView.vue'
import MatchViewPlug from '../../member/person/MatchViewPlug.vue'
export default {
  components: {
    Share: Share,
    Loading: Loading,
    Navbar: Navbar,
    DTLBaseView: DTLBaseView,
    DTLEduView: DTLEduView,
    DTLWorkView: DTLWorkView,
    DTLSkillView: DTLSkillView,
    DTLCertView: DTLCertView,
    DTLProjectView: DTLProjectView,
    MatchViewPlug: MatchViewPlug,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Image.name]: Image,
    [Divider.name]: Divider,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Tag.name]: Tag
  },
  data () {
    return {
      orderCode: '',
      loadingShow: false,
      processShow: false,
      role: 'USR',
      order: {},
      activeName: 'detail',
      photoList: [],
      person: {},
      personCode: ''
    }
  },
  mounted: function () {
    var query = this.$route.query
    this.orderCode = query.orderCode
    var role = query.role
    if (role !== null && role !== '' && role !== undefined) {
      this.role = role
    }
    this.retrieveOrder()
  },
  methods: {
    tabChange (name) {
      if (name === 'match') {
        setTimeout(() => {
          this.$refs.matchView.retrieveMatchList(this.order.personCode, this.order.taskCode)
        }, 1500)
      }
    },
    async retrieveOrder () {
      var pd = { orderCode: this.orderCode }
      const { data: res } = await this.$http.post(this.BMS_URL + '/recruit/order/retrieveOrder', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.order = res.data
        this.retrieveDetail()
      }
    },
    async retrieveDetail () {
      var pd = { code: this.order.personCode }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePerson', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.person = res.data
        this.photoList.push(res.data.photoPath)
        this.$refs.dtlBase.retrieveBase(this.order.personCode)
        this.$refs.dtlEdu.retrieveEdu(this.order.personCode)
        this.$refs.dtlWork.retrieveWork(this.order.personCode)
        this.$refs.dtlSkill.retrieveSkill(this.order.personCode)
        this.$refs.dtlCert.retrieveCert(this.order.personCode)
        this.$refs.dtlProject.retrieveProject(this.order.personCode)
      }
    },
    taskDetail () {
      window.sessionStorage.setItem(this.SESSION_BACKURL, window.location.href)
      this.$router.push({ path: '/mde/recruit/detail', query: { taskCode: this.order.taskCode } })
    },
    callPhone () {
      window.location.href = 'tel:' + this.person.phone
    },
    applyAudit () {
      this.$router.push({ path: '/mde/recruit/orderApplyAudit', query: { orderCode: this.orderCode } })
    },
    supplierAudit () {
      // this.$router.push({ path: '/recruit/orderSupplierAudit', query: { orderCode: this.orderCode, taskCode: this.order.taskCode } })
      this.uploadResume()
    },
    async uploadResume () {
      var pd = { personCode: this.order.personCode, type: 'RESUME' }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/exportPersonWord', this.$qs.stringify(pd))
      if (res.status === '200') {
        console.log(res.data)
      }
      // window.open('https://moc.utopanet.com/resume.docx')
    },
    back () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  ::v-deep .van-tabs__line {
    z-index: 0;
  }
}
.order {
  margin-top: 30px;
  font-size: 13px;
  .item {
    border-bottom: 1px dashed #eee;
    padding: 3px 0px;
    .title {
      text-align: right;
      height: 25px;
      line-height: 25px;
      font-weight: 600;
    }
    .text {
      text-align: left;
      height: 25px;
      line-height: 25px;
    }
    .btn {
      height: 25px;
      line-height: 25px;
    }
  }
}
.detail {
  margin-bottom: 80px;
  margin-left: 5%;
  width: 90%;
  .base {
    .item {
      border-bottom: 1px dashed #eee;
      padding: 5px 0px;
      .title {
        text-align: right;
      }
      .desc {
        text-align: left;
      }
    }
  }
}

.operate {
  padding: 5px 0px;
  background-color: #fff;
  height: 60px;
  position: fixed;
  width: 100%;
  display: flex;
  flex-flow: column;
  text-align: center;
  justify-content: center;
  bottom: 0px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #bbb;
  .btn {
    margin: auto 5px;
  }
  .dateBtn {
    margin: 20px auto;
  }
  .choseBack {
    background-color: #fff;
    z-index: 9005;
  }
}
.shareImg {
  width: 90%;
}
</style>
